import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

export default function PlayButton({
  setYear,
  setPlay,
  year,
  maxYear,
  minYear,
  play,
  filter,
  month,
  setMonth,
}) {
  useEffect(() => {
    var selectedYear = filter.year;
    var y = year;

    var monthNew;
    if (play) {
      monthNew = 1;
      setMonth("01");
    }

    let interval = setInterval(() => {
      if (play) {
        if (monthNew < 12) {
          monthNew = monthNew + 1;
          setMonth((monthNew < 10 ? "0" : "") + monthNew);
        } else {
          monthNew = 1;
          y++;
          setMonth((monthNew < 10 ? "0" : "") + monthNew);
          if (y < selectedYear || (y > selectedYear && y <= maxYear)) {
            setYear(y);
          } else if (y > maxYear) {
            // loop animation
            // y = minYear;
            // setYear(minYear);
            // stop animation in last year
            setYear(maxYear);
            setPlay(false);
            clearInterval(interval);
          } else if (y === selectedYear) {
            setYear(y);
            setPlay(false);
            clearInterval(interval);
          } else {
            setYear(y);
          }
        }
      }
    }, 125);
    return () => clearInterval(interval);
  }, [play, maxYear, minYear]);

  return (
    <Button
      variant="text"
      onClick={() => {
        if (filter.year) {
          if (!play) {
            setTimeout(function () {
              setPlay(!play);
            }, 1000);
          } else setPlay(!play);
        }
      }}
      aria-label="Play"
      style={{ width: "max-content", minWidth: "unset !important" }}
      className="remove-from-download"
    >
      {play ? <PauseIcon />:<PlayArrowIcon />}
    </Button>
  );
}
