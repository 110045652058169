import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

export default function PlayButtonBorder({
  setYear,
  play,
  year,
  setDate,
  maxYear,
  setPlay,
  setAccumulatedEvents,
  setShownDate,
  shownDate,
  setFloatingDateOpacity
}) {
  // const [endDate, setEndDate] = useState("");
  const [endDate, setEndDate] = useState(moment("2023-12-01", "YYYY-MM-DD"));
  const [startDate, setStartDate] = useState("");
  const [intervalId, setIntervalId] = useState(0);
  
  useEffect(() => {
    let day = moment(shownDate, "YYYY/MM/DD");

    const clearCurrentData = () => {
      setAccumulatedEvents(["any", ["in", year, ["get", "event_date"]]]);; // Clean acumulated current events
      // setFloatingDateOpacity(0); // optional
    };
  
  
    // Function to update the day and related statuses
    const updateDay = () => {
      const newDay = moment(day).add(1, "months");
      const isNewYear = newDay.format("MM") === "01" && newDay.format("DD") === "01";
  
      if (isNewYear) {
        clearCurrentData(); // clean data before setting new year
        setTimeout(function() {
          setYear(Number(newDay.format("yyyy")));
        }, 900); // wait time
      }
  
  
      setDate(newDay.format("YYYY/MM/DD"));
      if (!isNewYear) {
        setAccumulatedEvents((oldArray) => [
          ...oldArray,
          ["in", newDay.format("YYYY/MM"), ["get", "event_date"]],
        ]);
      }
      setShownDate(`${newDay.format("YYYY/MM")}`);
  
      return newDay;
    };
  
    // Check if the animation should start
    if (play && startDate !== '' && (shownDate === year || moment(shownDate, "YYYY/MM").isSame(endDate))) {
      setAccumulatedEvents([
        "any",
        ["in", startDate.format("YYYY/MM"), ["get", "event_date"]],
      ]);
      day = moment(startDate, "YYYY/MM/DD");
      setShownDate(`${moment(startDate, "YYYY/MM").format("YYYY/MM")}`);
      setFloatingDateOpacity(1);
    }
  
    // Set interval to update day
    const newIntervalId = setInterval(() => {
      if (play) {
        // Stop playback in December 2023
        if (day.format("YYYY/MM") === "2023/12") {
          clearInterval(newIntervalId);
          setIntervalId(0);
          setPlay(false);
          setFloatingDateOpacity(0);
    
          // Make sure to update to December 2023 data
          setDate(moment(day).format("YYYY/MM/DD"));
          setAccumulatedEvents((oldArray) => [
            ...oldArray,
            ["in", moment(day).format("YYYY/MM"), ["get", "event_date"]],
          ]);
          setShownDate(moment(day).format("YYYY/MM"));
          return;
        }

        day = updateDay(); // Update day and statuses
      }
    }, 1000);
  
    setIntervalId(newIntervalId);
    return () => clearInterval(newIntervalId);
  }, [play, startDate, shownDate, year, endDate]);

  useEffect(() => {
    return (
      // setEndDate(moment(`${year}-12-01`, "YYYY/MM/DD")),
      setEndDate(moment(`2023-12-11`, "YYYY/MM/DD")),
      setDate(moment(`${year}-01`, "YYYY/MM")),
      setStartDate(moment(`${year}-01-01`, "YYYY/MM/DD"))
    );
  }, [year]);

  return (
    <Button
      variant="text"
      onClick={() => setPlay(!play)}
      aria-label="Play"
      style={{ width: "max-content", minWidth: "unset !important" }}
      className="remove-from-download"
    >
      {play ? <PauseIcon /> : <PlayArrowIcon />}
    </Button>
  );
}
